import React from 'react'

import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Foot from '../../components/footer'

// import sousTitres from '../hoodie_hacking_stuff/the-end.vtt'

import '../../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'

const HackedHoodiePage = () => {

    return (<Layout languageSwitchLink="/hacked_hoodie_6519403540">
        <SEO title="Hacked Hoodie"/>
        <Banner class_suffix="deep"/>
        <center>
            <h3 style={{ fontSize: '400%' }}>Good job, hacker.</h3>
            <div style={{ width: '60%', margin: 'auto', marginTop: '5px' }}>
                <video
                    autoPlay
                    controls
                    style={{ width: '70%' }}
                >
                    <source src='/hoodie_hacking_stuff/the_end_06060987089887.mp4' type="video/mp4" />
                    Your browser does not support the video tag.
                    {/* <track
                        src={sousTitres}
                        kind="captions"
                        srcLang="en"
                        label="English"
                        default
                    /> */}
                </video>
            </div>
            <i>You might need to allow playing video and audio on your browser</i>
        </center>
        <Foot></Foot>
    </Layout>)
}
export default HackedHoodiePage